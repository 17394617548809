/* home.css */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html, body {
    margin: 0;
    min-height: 60%;
    background-color: #585154;
    background-image: linear-gradient(225deg, #585154 0%, #0a0310 50%, #3a5464 100%, #585154 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.home {
    width: 80%; /* Adjust the width as needed */
    margin: 1rem auto;
    background-color: transparent !important; /* Ensure the .home container background is transparent */
    position: relative; /* Ensure position is relative to allow absolute positioning inside */
}

.home h1,
.title {
    color: white;
    text-align: center;
    font-size: 200%;
    background-color: transparent !important; /* Ensure the h1 background is transparent */
}

.title {
    font-size: 400%;
    font-family: "Proxima Nova";
}

.slick-slider {
    margin: 0 auto; /* Center the slider horizontally */
    width: 50%; /* Set the width of the slider */
}

.slick-slide {
    transition: transform 0.8s ease; /* Smooth transition effect */
    background-color: transparent;
}

.slick-slide img {
    width: 100%; /* Ensure images fill the slider container */
    height: auto;
    background-color: transparent;
}

.slick-list {
    overflow: hidden; /* Hide overflow to prevent images from showing outside the slider */
    background-color: transparent;
}

.slick-slide:not(.slick-center) {
    opacity: 0.5; /* Reduce opacity for non-center images */
    transform: scale(0.8); /* Scale down non-center images */
    background-color: transparent;
}

.slick-center {
    transform: scale(1); /* Ensure center image is at full scale */
    background-color: transparent;
}

.bottom-sections {
    display: flex;
    justify-content: space-between; /* Space items evenly */
    margin-top: 20px; /* Adjust margin as needed */
}

.left-box {
    flex: 1; /* Take up 1 part of the available space */
    max-width: 80%; /* Adjust width as needed */
    background-color: #000; /* Adjust color as needed */
    border-radius: 15px;
    color: white;
    text-align: center;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
}

.right-box {
    flex: 5; /* Take up 5 parts of the available space */
    max-width: 90%; /* Adjust width as needed */
    background-color: #000; /* Adjust color as needed */
    border-radius: 15px;
    color: white;
    text-align: center;
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
    margin-left: 10px;
}

.wavesurfer-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px; /* Add margin to lower the controls */
}

.play-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #F90;
    transition: transform 0.2s ease-in-out;
}

.play-button:hover {
    transform: scale(1.1);
}

input[type="range"] {
    width: 100px;
    height: 5px;
    -webkit-appearance: none;
    background-color: #34374B;
    border-radius: 5px;
    margin-top: 4px; /* Adjust margin to lower the slider */
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #34374B;
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #F90;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #34374B;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #34374B;
    border-radius: 5px;
}

input[type="range"]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #F90;
    cursor: pointer;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #34374B;
    border-radius: 5px;
}

input[type="range"]::-ms-fill-lower {
    background: #34374B;
    border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
    background: #34374B;
    border-radius: 5px;
}

input[type="range"]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #F90;
    cursor: pointer;
}

.wavesurfer-container {
    flex: 1;
    position: relative;
    height: 20px;
}
